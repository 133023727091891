<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container
      fluid
    >
      <base-material-card
        color="primary"
        icon="mdi-account"
        inline
        class="px-5 py-3"
      >
        <template v-slot:after-heading>
          <v-card-text class="display-3 font-weight-light">
            {{ appTitle }}
          </v-card-text>
        </template>
        <v-row>
          <v-card-text>
            {{ this.$t('userDetailsInfo') }}
          </v-card-text>
        </v-row>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="name"
              :disabled="nameDisabled"
              :error-messages="nameErrors"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelName')"
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="email"
              :disabled="emailDisabled"
              :error-messages="emailErrors"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelEMail')"
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="phonenumber"
              v-mask="'(###) ###-####'"
              :disabled="phonenumberDisabled"
              :error-messages="phoneNumberErrors"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelPhone')"
              @input="$v.phonenumber.$touch()"
              @blur="$v.phonenumber.$touch()"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="loginUserId"
              :disabled="loginUserIdDisabled"
              class="purple-input"
              dense
              label="login userId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="companyName"
              :disabled="companyNameDisabled"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelOrg')"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="jobTitle"
              :disabled="jobTitleDisabled"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelTitle')"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="contactName"
              :disabled="contactNameDisabled"
              class="purple-input"
              dense
              :label="this.$t('registerCardLabelContactName')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="status"
              :items="statusCodes"
              item-text="value"
              item-value="key"
              dense
              class="purple-input"
              :label="this.$t('userDetailsStatus')"
            />
          </v-col>
          <v-col cols="4" />
          <v-col cols="4">
            <v-text-field
              v-model="contactEmail"
              v-mask="'A######@emn.com'"
              placeholder="u123456@emn.com"
              dense
              class="purple-input"
              :label="this.$t('userDetailsContactEmail')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-card-text>
            {{ this.$t('userDetailsAccess') }}
          </v-card-text>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-select
              v-model="partner"
              :items="partners"
              item-text="name"
              item-value="partnerId"
              dense
              class="purple-input"
              :label="this.$t('userDetailsPartner')"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="role"
              :items="roles"
              item-text="name"
              item-value="roleId"
              dense
              class="purple-input"
              :label="this.$t('userDetailsRole')"
            />
          </v-col>
        </v-row>
        <template v-slot:actions>
          <div>
            <v-btn
              :disabled="disableSubmit"
              color="success"
              @click="onUserUpdate"
            >
              {{ $t('buttonSave') }}
            </v-btn>
            <v-btn
              v-if="user !== undefined && user.statusCode === 3"
              color="primary"
              @click="sendInvite"
            >
              {{ $t('buttonInvite') }}
            </v-btn>
            <v-btn
              text
              to="/admin/Users"
            >
              {{ $t('buttonClose') }}
            </v-btn>
          </div>
        </template>
      </base-material-card>
    </v-container>
    <error-dialog
      dialog-type="error"
      :dialog="showErrorDialog"
      :title="$t('errorTitleProcess')"
      :message="errorMessage"
      @update:dialog="showErrorDialog = false"
    />
    <process-overlay
      :processing="modelProcessing"
    />
  </div>
</template>

<script>
  import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'
  import { validationMixin } from 'vuelidate'
  import { mapFields } from 'vuex-map-fields'
  import userAPI from '@/services/userServices'
  import partnerAPI from '@/services/partnerServices'
  import i18n from '@/i18n'
  // import graphAPI from '@/services/graphServices'

  function initialState () {
    return {
      appTitle: i18n.t('titleUserDetails'),
      breadcrumbs: [
        {
          text: i18n.t('bcHome'),
          disabled: false,
          href: '/dashboard',
        },
        {
          text: i18n.t('bcAdmin'),
          disabled: true,
          href: '/admin',
        },
        {
          text: i18n.t('bcUsers'),
          disabled: false,
          href: '/admin/users',
        },
        {
          text: i18n.t('bcUserDetails'),
          disabled: true,
          href: '/admin/userdetails',
        },
      ],
      showErrorDialog: false,
      errorMessage: '',
      environment: process.env.VUE_APP_ENVIRONMENT,
      modelProcessing: false,
      user: undefined,
      role: undefined,
      partner: undefined,
      email: '',
      emailDisabled: true,
      phonenumber: '',
      phonenumberDisabled: true,
      name: '',
      nameDisabled: true,
      loginUserId: '',
      loginUserIdDisabled: true,
      companyName: '',
      companyNameDisabled: true,
      jobTitle: '',
      jobTitleDisabled: true,
      contactName: '',
      contactNameDisabled: true,
      contactEmail: '',
      status: undefined,
      roles: [],
      partners: [],
    }
  }
  export default {
    Name: 'EditUser',
    components: {
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
      ErrorDialog: () => import('@/views/components/core/ErrorDialog'),
      ProcessOverlay: () => import('@/views/components/core/Overlay'),
    },
    mixins: [validationMixin],
    props: {
      userId: {
        type: String,
        default: '0',
      },
    },
    data: function () {
      return initialState()
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        statusCodes: 'statusCodes',
      }),
      disableSubmit: function () {
        // return this.$v.$invalid
        return false
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push(this.$t('registerCardErrorNameMax'))
        !this.$v.name.minLength && errors.push(this.$t('registerCardErrorNameMin'))
        !this.$v.name.required && errors.push(this.$t('registerCardErrorNameRequired'))
        return errors
      },
      companyNameErrors () {
        const errors = []
        if (!this.$v.companyName.$dirty) return errors
        !this.$v.companyName.maxLength && errors.push(this.$t('registerCardErrorOrgMax'))
        !this.$v.companyName.minLength && errors.push(this.$t('registerCardErrorOrgMin'))
        !this.$v.companyName.required && errors.push(this.$t('registerCardErrorOrgRequired'))
        return errors
      },
      titleErrors () {
        const errors = []
        if (!this.$v.jobTitle.$dirty) return errors
        !this.$v.jobTitle.required && errors.push(this.$t('registerCardErrorTitleRequired'))
        return errors
      },
      phoneNumberErrors () {
        const errors = []
        if (!this.$v.phonenumber.$dirty) return errors
        !this.$v.phonenumber.required && errors.push(this.$t('registerCardErrorPhoneRequired'))
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push(this.$t('registerCardErrorEMailInvalid'))
        !this.$v.email.required && errors.push(this.$t('registerCardErrorEMailRequired'))
        return errors
      },
      contactNameErrors () {
        const errors = []
        if (!this.$v.contactName.$dirty) return errors
        !this.$v.contactName.required && errors.push(this.$t('registerCardErrorContactRequired'))
        return errors
      },
    },
    mounted: function () {
      this.modelProcessing = true
      userAPI.getUserById(this.userId)
        .then(response => {
          this.user = response.data
          this.role = this.user.roleId
          this.partner = this.user.partnerId
          this.name = this.user.name
          this.phonenumber = this.user.mobilePhone
          this.loginUserId = this.user.loginUserId
          this.email = this.user.eMail
          this.companyName = this.user.companyName
          this.jobTitle = this.user.jobTitle
          this.contactName = this.user.eastmanContactName
          this.status = String(this.user.statusCode)
          this.contactEmail = this.user.eastmanContactEMail
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
      userAPI.getApplicationRoles()
        .then(response => {
          this.modelProcessing = true
          this.roles = response.data
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
      partnerAPI.getAllPartners()
        .then(response => {
          this.partners = response.data
          this.modelProcessing = false
        })
        .catch(error => {
          this.showErrorDialog = true
          this.errorMessage = error.message
          this.modelProcessing = false
        })
      if (this.statusCodes.length === 0) {
        this.$store.dispatch('GET_STATUSCODES')
      }
    },
    validations: {
      name: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(80),
      },
      companyName: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(120),
      },
      jobTitle: {
        required,
      },
      phonenumber: {
        required,
      },
      email: {
        required,
        email,
      },
      contactName: {
        required,
      },
    },
    methods: {
      onUserUpdate () {
        // this.$v.$touch()
        // if (!this.$v.$invalid) {
        this.modelProcessing = true
        this.user.name = this.name
        this.user.loginUserId = this.loginUserId
        this.user.eMail = this.email
        this.user.mobilePhone = this.phonenumber
        this.user.companyName = this.companyName
        this.user.jobTitle = this.jobTitle
        this.user.eastmanContactName = this.contactName
        this.user.roleId = this.role
        this.user.partnerId = this.partner
        this.user.statusCode = parseInt(this.status)
        this.user.eastmanContactEMail = this.contactEmail
        userAPI.updateUser(this.user)
          .then(response => {
            this.$router.push('/admin/Users')
          })
          .catch(error => {
            this.showErrorDialog = true
            this.errorMessage = error.message
            this.modelProcessing = false
          })
        // }
      },
      sendInvite () {
        this.modelProcessing = true
        userAPI.inviteUser(this.userId)
          .then(response => {
            // console.log(response)
            if (response.data === null) {
              this.errorMessage = response.errorMessage
              this.showErrorDialog = true
            } else {
              alert(this.$t('alertInviteComplete'))
            }
            this.modelProcessing = false
          })
          .catch(error => {
            this.errorMessage = error.message
            this.showErrorDialog = true
            this.modelProcessing = false
          })
      },
    },
  }
</script>
